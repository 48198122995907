import React, { useState, useCallback } from 'react';
import DedicatedPlanForm from './forms/DedicatedPlanForm';

const states = [
  'Lagos',
  'Abuja',
  'Rivers',
  'Oyo',
  'Kano',
  'Kaduna',
  'Enugu',
  'Delta',
  'Edo',
  'Anambra'
];

const cities = {
  Lagos: ['Ikeja', 'Lekki', 'Victoria Island', 'Surulere', 'Yaba', 'Ajah', 'Ikorodu', 'Apapa'],
  Abuja: ['Central Business District', 'Garki', 'Wuse', 'Maitama', 'Asokoro', 'Gwarinpa'],
  Rivers: ['Port Harcourt', 'Obio-Akpor', 'Eleme', 'Oyigbo', 'Bonny'],
  Oyo: ['Ibadan', 'Ogbomosho', 'Oyo', 'Iseyin', 'Saki'],
  Kano: ['Kano Municipal', 'Nassarawa', 'Dala', 'Gwale', 'Tarauni'],
  Kaduna: ['Kaduna North', 'Kaduna South', 'Zaria', 'Kafanchan', 'Kagoro'],
  Enugu: ['Enugu North', 'Enugu South', 'Nsukka', 'Oji River', 'Awgu'],
  Delta: ['Warri', 'Asaba', 'Sapele', 'Ughelli', 'Agbor'],
  Edo: ['Benin City', 'Auchi', 'Ekpoma', 'Uromi', 'Igarra'],
  Anambra: ['Awka', 'Onitsha', 'Nnewi', 'Ekwulobia', 'Aguata']
};

function CustomBandwidthSelector() {
  const [bandwidth, setBandwidth] = useState(50);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleBandwidthChange = useCallback((e) => {
    const value = parseInt(e.target.value, 10);
    setBandwidth(value);
  }, []);

  const handleStateChange = useCallback((e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity('');
  }, []);

  const formatBandwidth = useCallback((value) => {
    return value >= 1000 ? '1 Gbps' : `${value} Mbps`;
  }, []);

  return (
    <div className="bg-white rounded-xl shadow-lg p-8 max-w-2xl mx-auto">
      <h3 className="text-2xl font-bold text-gray-900 mb-6">Custom Bandwidth Calculator</h3>
      
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-semibold text-gray-900 mb-2">
            Select State
          </label>
          <select
            value={selectedState}
            onChange={handleStateChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select a state</option>
            {states.map(state => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-900 mb-2">
            Select City
          </label>
          <select
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            disabled={!selectedState}
          >
            <option value="">Select a city</option>
            {selectedState && cities[selectedState].map(city => (
              <option key={city} value={city}>{city}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-900 mb-2">
            Select Bandwidth
          </label>
          <input
            type="range"
            min="50"
            max="1000"
            step="50"
            value={bandwidth}
            onChange={handleBandwidthChange}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          />
          <div className="mt-2 text-center text-lg font-semibold text-blue-600">
            {formatBandwidth(bandwidth)}
          </div>
        </div>

        <button
          onClick={() => setIsFormOpen(true)}
          disabled={!selectedState || !selectedCity}
          className={`w-full py-3 px-4 rounded-md text-white font-semibold transition duration-300 ${
            !selectedState || !selectedCity
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700'
          }`}
        >
          Request Quote
        </button>
      </div>

      <DedicatedPlanForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        selectedPlan={`Custom ${formatBandwidth(bandwidth)} Plan`}
        selectedState={selectedState}
        selectedCity={selectedCity}
        bandwidth={bandwidth}
      />
    </div>
  );
}

export default CustomBandwidthSelector;