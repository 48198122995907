import React, { useState } from 'react';
import SharedPlanForm from './forms/SharedPlanForm';

function PlanCard({ plan }) {
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <>
      <div className="border border-gray-200 rounded-lg shadow-sm p-6 bg-white hover:shadow-lg transition-shadow duration-300">
        <h3 className="text-2xl font-bold text-gray-900">{plan.name}</h3>
        <p className="mt-4 text-5xl font-extrabold text-gray-900">
          ₦{plan.price}
        </p>
        <p className="mt-2 text-gray-500">per month</p>
        <p className="mt-4 text-lg font-semibold text-blue-600">
          Up to {plan.speed} speed
        </p>
        <ul className="mt-6 space-y-4">
          {plan.features.map((feature) => (
            <li key={feature} className="flex items-center">
              <svg
                className="h-5 w-5 text-green-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
              <span className="ml-3 text-gray-700">{feature}</span>
            </li>
          ))}
        </ul>
        <button 
          onClick={() => setIsFormOpen(true)}
          className="mt-8 w-full bg-blue-600 text-white rounded-md py-2 hover:bg-blue-700 transition-colors duration-300"
        >
          Get Started
        </button>
      </div>

      <SharedPlanForm 
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        selectedPlan={plan.name}
      />
    </>
  );
}

export default PlanCard;