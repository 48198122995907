import React, { useState, useEffect } from 'react';
import Modal from '../ui/Modal';

function DedicatedPlanForm({ isOpen, onClose, selectedPlan, selectedState, selectedCity, bandwidth }) {
  const [formData, setFormData] = useState({
    companyName: '',
    fullName: '',
    email: '',
    phone: '',
    plan: selectedPlan || '',
    companySize: '1-10',
    industry: '',
    address: '',
    preferredTime: 'morning',
    notes: '',
    state: selectedState || '',
    city: selectedCity || '',
    bandwidth: bandwidth ? bandwidth.toString() : '',
  });

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      plan: selectedPlan || '',
      state: selectedState || '',
      city: selectedCity || '',
      bandwidth: bandwidth ? bandwidth.toString() : '',
    }));
  }, [selectedPlan, selectedState, selectedCity, bandwidth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://chat.googleapis.com/v1/spaces/AAAA5gdZt3g/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=uktDjWsFCN5fchbSXuabJu6RLT7eAdEylgAiroYg7b4', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: `New Dedicated Plan Request\n
          Company Name: ${formData.companyName}
          Full Name: ${formData.fullName}
          Email: ${formData.email}
          Phone: ${formData.phone}
          Selected Plan: ${formData.plan}
          Bandwidth: ${formData.bandwidth} Mbps
          State: ${formData.state}
          City: ${formData.city}
          Company Size: ${formData.companySize}
          Industry: ${formData.industry}
          Address: ${formData.address}
          Preferred Time: ${formData.preferredTime}
          Notes: ${formData.notes}`
        })
      });

      if (response.ok) {
        alert('Form submitted successfully!');
        onClose();
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form. Please try again.');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Request Dedicated Plan Quote">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-900">Company Name</label>
          <input
            type="text"
            name="companyName"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
            value={formData.companyName}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-900">Full Name</label>
          <input
            type="text"
            name="fullName"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
            value={formData.fullName}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-900">Email</label>
          <input
            type="email"
            name="email"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-900">Phone Number</label>
          <input
            type="tel"
            name="phone"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-900">State</label>
            <input
              type="text"
              name="state"
              required
              readOnly
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm text-gray-900"
              value={formData.state}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900">City</label>
            <input
              type="text"
              name="city"
              required
              readOnly
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm text-gray-900"
              value={formData.city}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-900">Company Size</label>
          <select
            name="companySize"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
            value={formData.companySize}
            onChange={handleChange}
          >
            <option value="1-10">1-10 employees</option>
            <option value="11-50">11-50 employees</option>
            <option value="51-200">51-200 employees</option>
            <option value="201-500">201-500 employees</option>
            <option value="500+">500+ employees</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-900">Industry</label>
          <input
            type="text"
            name="industry"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
            value={formData.industry}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-900">Address</label>
          <textarea
            name="address"
            required
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
            value={formData.address}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-900">Preferred Contact Time</label>
          <select
            name="preferredTime"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
            value={formData.preferredTime}
            onChange={handleChange}
          >
            <option value="morning">Morning</option>
            <option value="afternoon">Afternoon</option>
            <option value="evening">Evening</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-900">Additional Notes</label>
          <textarea
            name="notes"
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
            value={formData.notes}
            onChange={handleChange}
          />
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default DedicatedPlanForm;