import { useState, useEffect } from 'react';

export function useExitIntent(options = {}) {
  const { threshold = 50, eventThrottle = 100 } = options;
  const [isVisible, setIsVisible] = useState(false);
  const [hasShown, setHasShown] = useState(false);

  useEffect(() => {
    let lastMouseY = 0;
    let lastMouseX = 0;
    let throttleTimeout = null;

    const handleMouseMove = (event) => {
      if (throttleTimeout) return;

      throttleTimeout = setTimeout(() => {
        const { clientY, clientX } = event;
        
        if (
          !hasShown &&
          clientY < threshold &&
          clientY < lastMouseY &&
          Math.abs(clientX - lastMouseX) < 50
        ) {
          setIsVisible(true);
          setHasShown(true);
        }
        
        lastMouseY = clientY;
        lastMouseX = clientX;
        throttleTimeout = null;
      }, eventThrottle);
    };

    if (!hasShown) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      if (throttleTimeout) clearTimeout(throttleTimeout);
    };
  }, [threshold, eventThrottle, hasShown]);

  return {
    isVisible,
    setIsVisible
  };
}