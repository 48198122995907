import React from 'react';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';

function Home() {
  return (
    <>
      <Hero />
      <div className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Our Internet Solutions
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Choose the perfect internet plan for your needs
            </p>
          </div>

          <div className="mt-20 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Shared Home Plans</h3>
              <p className="text-gray-600 mb-6">
                Perfect for homes and small businesses with shared bandwidth options starting from 8Mbps.
              </p>
              <Link
                to="/shared-plans"
                className="inline-block w-full text-center py-3 px-4 rounded-md bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors"
              >
                View Shared Plans
              </Link>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Dedicated Plans</h3>
              <p className="text-gray-600 mb-6">
                Exclusive bandwidth for businesses requiring consistent, high-speed internet performance.
              </p>
              <Link
                to="/dedicated-plans"
                className="inline-block w-full text-center py-3 px-4 rounded-md bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors"
              >
                View Dedicated Plans
              </Link>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Estate Solutions</h3>
              <p className="text-gray-600 mb-6">
                Complete internet infrastructure solutions for estates and apartment buildings.
              </p>
              <Link
                to="/estate-solutions"
                className="inline-block w-full text-center py-3 px-4 rounded-md bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors"
              >
                View Estate Solutions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;