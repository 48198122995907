import React from 'react';
import { FaPhone, FaEnvelope, FaWhatsapp } from 'react-icons/fa';

function Contact() {
  return (
    <div id="contact" className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Contact Us
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            We're here to help you get connected
          </p>
        </div>

        <div className="mt-20 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div className="text-center">
            <div className="flex justify-center">
              <FaPhone className="h-12 w-12 text-blue-600" />
            </div>
            <h3 className="mt-6 text-lg font-medium text-gray-900">Phone</h3>
            <p className="mt-2 text-base text-gray-500">
              <a href="tel:+2349167719319" className="text-blue-600 hover:text-blue-800">
                +234 916 771 9319
              </a>
            </p>
          </div>

          <div className="text-center">
            <div className="flex justify-center">
              <FaEnvelope className="h-12 w-12 text-blue-600" />
            </div>
            <h3 className="mt-6 text-lg font-medium text-gray-900">Email</h3>
            <p className="mt-2 text-base text-gray-500">
              <a href="mailto:info@ribapnetworks.com" className="text-blue-600 hover:text-blue-800">
                info@ribapnetworks.com
              </a>
            </p>
          </div>

          <div className="text-center">
            <div className="flex justify-center">
              <FaWhatsapp className="h-12 w-12 text-blue-600" />
            </div>
            <h3 className="mt-6 text-lg font-medium text-gray-900">WhatsApp</h3>
            <p className="mt-2 text-base text-gray-500">
              <a href="https://wa.me/2349167719319" className="text-blue-600 hover:text-blue-800">
                Chat with us
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;