import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import SharedPlans from './pages/SharedPlans';
import DedicatedPlans from './pages/DedicatedPlans';
import EstateSolutions from './pages/EstateSolutions';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './pages/Home';
import ExitPopup from './components/ExitPopup';
import { useExitIntent } from './hooks/useExitIntent';

function App() {
  const { isVisible, setIsVisible } = useExitIntent({
    threshold: 50,
    eventThrottle: 100
  });

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shared-plans" element={<SharedPlans />} />
          <Route path="/dedicated-plans" element={<DedicatedPlans />} />
          <Route path="/estate-solutions" element={<EstateSolutions />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <FAQ />
        <Contact />
        <Footer />
        <ExitPopup isOpen={isVisible} onClose={() => setIsVisible(false)} />
      </div>
    </Router>
  );
}

export default App;