import React from 'react';

const faqs = [
  {
    question: 'Total cost to get setup?',
    answer: 'One time setup fee + subscription. Installation cost determined post-survey.'
  },
  {
    question: 'How long does it take after payment to get installed?',
    answer: 'It takes 1 to 5 working days after payment to get installed.'
  },
  {
    question: 'Is your network secure?',
    answer: 'Yes, our wireless network employs the latest and most robust wireless encryption available with state-of-the-art equipment.'
  }
];

function FAQ() {
  return (
    <div id="faq" className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
        </div>
        <div className="mt-12">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
            {faqs.map((faq) => (
              <div key={faq.question}>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default FAQ;