import React from 'react';
import PlanCard from '../components/PlanCard';

const sharedPlans = [
  {
    name: 'Home Basic',
    price: '21,775',
    speed: '8Mbps',
    features: [
      'Unlimited Downloads',
      'Unlimited Streaming',
      'Access to all Websites',
      '30 days Validity'
    ]
  },
  {
    name: 'Home Premium',
    price: '43,550',
    speed: '16Mbps',
    features: [
      'Unlimited Downloads',
      'Unlimited Streaming',
      'Access to all Websites',
      '30 days Validity'
    ]
  },
  {
    name: 'Business Basic',
    price: '87,500',
    speed: '25Mbps',
    features: [
      'Unlimited Downloads',
      'Unlimited Streaming',
      'Access to all Websites',
      '30 days Validity'
    ]
  }
];

function SharedPlans() {
  return (
    <div className="pt-16">
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-white text-center">Shared Home Plans</h1>
          <p className="mt-4 text-xl text-blue-100 text-center max-w-3xl mx-auto">
            Shared Plans involve a set bandwidth shared among users on a shared Internet connection. 
            All bandwidth is split amongst all users and devices.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {sharedPlans.map((plan) => (
            <PlanCard key={plan.name} plan={plan} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SharedPlans;