import React from 'react';
import CustomBandwidthSelector from '../components/CustomBandwidthSelector';

function DedicatedPlans() {
  return (
    <div className="pt-16">
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-white text-center">Dedicated Plans</h1>
          <p className="mt-4 text-xl text-blue-100 text-center max-w-3xl mx-auto">
            Dedicated Plans provide bandwidth that is exclusively dedicated to your business. 
            With stable download and upload speeds and no slowness during peak usage periods.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <CustomBandwidthSelector />
      </div>
    </div>
  );
}

export default DedicatedPlans;