import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

function Hero() {
  return (
    <div id="home" className="pt-16 bg-gradient-to-r from-blue-600 to-blue-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
            <span className="block">Fast & Reliable</span>
            <span className="block text-blue-200">Internet Solutions</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-blue-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Experience lightning-fast internet with Ribap Networks. We provide dedicated and shared internet solutions for homes and businesses.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8 space-x-4">
            <RouterLink
              to="/shared-plans"
              className="inline-block rounded-md shadow px-8 py-3 bg-white text-blue-600 font-medium hover:bg-blue-50"
            >
              Shared Plans
            </RouterLink>
            <RouterLink
              to="/dedicated-plans"
              className="inline-block rounded-md shadow px-8 py-3 bg-blue-500 text-white font-medium hover:bg-blue-600"
            >
              Dedicated Plans
            </RouterLink>
            <RouterLink
              to="/estate-solutions"
              className="inline-block rounded-md shadow px-8 py-3 bg-blue-800 text-white font-medium hover:bg-blue-900"
            >
              Estate Solutions
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;