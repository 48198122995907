import React, { useState } from 'react';
import EstateForm from './forms/EstateForm';

function EstateSection() {
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <div className="mt-32 bg-blue-600 rounded-xl p-12 text-white">
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-4">Estate & Apartment Solutions</h2>
        <p className="text-xl mb-12">
          We provide complete internet infrastructure for estates and apartment buildings
        </p>

        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-blue-500/30 p-8 rounded-xl">
            <h3 className="text-2xl font-semibold mb-6">What We Offer</h3>
            <ul className="space-y-4">
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Full infrastructure setup at our cost
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Minimum 4-year partnership agreement
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                High-speed internet for all residents
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Dedicated maintenance team
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Competitive resident pricing
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Regular infrastructure upgrades
              </li>
            </ul>
          </div>

          <div className="bg-blue-500/30 p-8 rounded-xl">
            <h3 className="text-2xl font-semibold mb-6">Benefits</h3>
            <ul className="space-y-4">
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                No upfront infrastructure cost for the estate
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Professional network management
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Reliable, high-speed internet for all residents
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                24/7 technical support
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Regular performance monitoring
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Future-proof infrastructure
              </li>
            </ul>
          </div>
        </div>

        <button 
          onClick={() => setIsFormOpen(true)}
          className="mt-12 bg-white text-blue-600 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-blue-50 transition-colors duration-300"
        >
          Contact Us for Estate Solutions
        </button>
      </div>

      <EstateForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
      />
    </div>
  );
}

export default EstateSection;