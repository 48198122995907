import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaWifi } from 'react-icons/fa';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { name: 'Home', to: '/', type: 'route' },
    { name: 'Shared Plans', to: '/shared-plans', type: 'route' },
    { name: 'Dedicated Plans', to: '/dedicated-plans', type: 'route' },
    { name: 'Estate Solutions', to: '/estate-solutions', type: 'route' },
    { name: 'FAQ', to: 'faq', type: 'scroll' },
    { name: 'Contact', to: 'contact', type: 'scroll' }
  ];

  const renderNavLink = (item) => {
    if (item.type === 'scroll') {
      return (
        <ScrollLink
          key={item.name}
          to={item.to}
          smooth={true}
          duration={500}
          className="text-gray-700 hover:text-blue-600 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          {item.name}
        </ScrollLink>
      );
    }
    return (
      <RouterLink
        key={item.name}
        to={item.to}
        className={`text-gray-700 hover:text-blue-600 cursor-pointer ${
          location.pathname === item.to ? 'text-blue-600 font-semibold' : ''
        }`}
        onClick={() => setIsOpen(false)}
      >
        {item.name}
      </RouterLink>
    );
  };

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <RouterLink to="/" className="flex items-center space-x-2">
              <FaWifi className="h-8 w-8 text-blue-600" />
              <span className="text-xl font-bold text-gray-900">Ribap Networks</span>
            </RouterLink>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            {navItems.map(renderNavLink)}
            <a
              href="tel:+2349167719319"
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            >
              Call Us
            </a>
          </div>

          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-700 hover:text-blue-600"
            >
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map(renderNavLink)}
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;