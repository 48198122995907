import React from 'react';
import EstateSection from '../components/EstateSection';

function EstateSolutions() {
  return (
    <div className="pt-16">
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-white text-center">Estate & Apartment Solutions</h1>
          <p className="mt-4 text-xl text-blue-100 text-center max-w-3xl mx-auto">
            Complete internet infrastructure solutions for estates and apartment buildings with 
            professional network management and 24/7 support.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <EstateSection />
      </div>
    </div>
  );
}

export default EstateSolutions;